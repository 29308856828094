import { useContext, useDebugValue, useEffect, useState } from "react";
import "./style.css";

import TelaGeral from "../../components/TelaGeral";
import { AuthContext } from "../../context/Auth";
import MenuSideBar from "../../components/MenuSideBar";
import api, {MENSAGEM_ERRO_HTTP} from "../../api";
import PanelCollapse from "../../components/PanelCollapse";
import { toastError, toastInfo, firstDayOfMonth, dateNow } from "../../library";
import { ButtonPesquisa, ButtonImprime } from "../../components/Button";
import TotalRegistro from "../../components/TotalRegistro";
import { IoMdPrint } from 'react-icons/io';
import Loading from "../../components/Loading";

function toogleImprime(checked, disabled) {
    let checkboxes = document.getElementsByName("imprime");

    for (let i=0, n=checkboxes.length; i<n; i++) {
        if (checked !== null)
            checkboxes[i].checked = checked;

        checkboxes[i].disabled = disabled;
    }

    checkboxes = document.getElementById("id_marca_todos");
    if (checkboxes) {
        checkboxes.disabled = disabled;
    }
};

function toogleMarcaTodos(element) {
    toogleImprime(element.target.checked, false);
}

async function getListImprime() {
    let lista = [];
    let checkboxes = document.getElementsByName("imprime");

    for (let i=0, n=checkboxes.length; i<n; i++) {
        if (checkboxes[i].checked)
            lista.push(parseInt(checkboxes[i].id));
    }    

    return lista;
}

const OrdemServicoItem = (item, setModalDetail, setItemDetails, relatorio) => {
    return (
        <div key={item.id} className="os-item">
            <div className="os-item-print">
                <input type="checkbox" id={item.id} name="imprime" checked={relatorio === "S"} disabled={relatorio === "S"} />
                <span>Imprimir</span>                
            </div>

            <div className="os-item-title">
                <span>{item.id} - {item.descricao}</span>
            </div>            

            <div className="os-detail">
                <span className="os-detail-titulo">Data solicitação:</span>
                <span className="os-detail-valor">{item.dataSolicitacao}</span>
            </div>

            <div className="os-detail">
                <span className="os-detail-titulo">Data execução:</span>
                <span className="os-detail-valor">{item.dataExecucao}</span>
            </div>           

            <div className="os-detail">
                <span className="os-detail-titulo">Solicitante:</span>
                <span className="os-detail-valor">{item.solicitante}</span>
            </div>                                  

            <div className="os-options">
                <span className="os-options-button" onClick={() => {setModalDetail(true); setItemDetails(item)}}>Detalhes</span>
            </div>
        </div>

    );
}

const OrdemServico = () => {
    const {setTitle} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [loadingPrint, setLoadingPrint] = useState(false);
    const [situacao, setSituacao] = useState("NF");
    const [relatorio, setRelatorio] = useState("A");
    const [data, setData] = useState([]);
    const [modalDetails, setModalDetails] = useState(false);
    const [itemDetails, setItemDetails] = useState([]);
    const [tipoServicoList, setTipoServicoList] = useState([]);
    const [tipoServico, setTipoServico] = useState("");
    const [solicitanteList, setSolicitanteList] = useState([]);
    const [solicitante, setSolicitante] = useState("");     
    const [finalizadaInicial, setFinalizadaInicial] = useState(firstDayOfMonth());
    const [finalizadaFinal, setFinalizadaFinal] = useState(dateNow());          
    const [numeroOS, setNumeroOS] = useState("");
    const [numeroNF, setNumeroNF] = useState("");  
    const [descricao, setDescricao] = useState("");  
    const [status, setStatus] = useState("T");

    useEffect(() => {
        (async() => {
            setTitle("Ordem de serviço");            
            handleFiltra();
        })();
    }, []);

    useEffect(() => {
        if (relatorio === "S")
            toogleImprime(true, true);            
        else
            toogleImprime(null, false);
    }, [relatorio]);

    useEffect(() => {
        setData([]);

        if (situacao === "NF")
            handleFiltra()
        else if (situacao === "PS") {
            (async () => {
                let response;
                //TIPO SERVICO
                if (! tipoServicoList.length) {
                    try {
                        response = await (await api.get(
                            `/ordemservicoTipoServicoLista`
                        )).data;
                        setTipoServicoList(response.list);
                    } catch {}
                }

                //SOLICITANTE
                if (! solicitanteList.length) {
                    try {
                        response = await (await api.get(
                            `/ordemservicoSolicitanteLista`
                        )).data;
                        setSolicitanteList(response.list);
                    } catch {}     
                }                
            })();
        }
    }, [situacao]);

    const handleFiltra = async () => {
        setLoading(true);
        setData([]);

        try {
            let response = await (await api.get(
                `/ordemservicoLista?situacao=${situacao}&finalizadaInicial=${finalizadaInicial}&finalizadaFinal=${finalizadaFinal}&numeroOS=${numeroOS}&numeroNF=${numeroNF}&status=${status}&descricao=${descricao}&tipoServico=${tipoServico}&solicitante=${solicitante}`
            )).data;

            setData(response.list);
        } catch {
            //MENSAGEM_ERRO_HTTP();
        } finally{
            setLoading(false)
        }
    }

    async function handleImprime (id = null) {
        let lista = await getListImprime();
        if (! lista.length && !id) {
            toastError("Selecione algum registro para imprimir", 2000);
        } else {
            setLoadingPrint(true);

            let url;

            if (id)
                url = `/ordemservicoAnaliticoVisualiza?idLista=${JSON.stringify([parseInt(id)])}`; 
            else if (relatorio === "A")
                url = `/ordemservicoAnaliticoVisualiza?idLista=${JSON.stringify(lista)}`; 
            else
                url = `/ordemservicoSinteticoVisualiza?idLista=${JSON.stringify(lista)}`;

            api.get(url, {
                responseType: "blob",
            })
            .then(async (response) => {
                if (response.status !== 200) {
                    MENSAGEM_ERRO_HTTP();
                } else {
                    if (response.data.type === "text/html") {
                        toastError("Arquivo não localizado", 2000);
                    } else {                        
                        let url = window.URL.createObjectURL(response.data);
                        window.open(url, "_blank");
                    }            
                }
            }).catch(function () {
                MENSAGEM_ERRO_HTTP();
            }).finally(() => setLoadingPrint(false));
        }
    }

    return (
        <>
            <Loading isOpen={loadingPrint} />

            <MenuSideBar>
                <div className="os-container">
                    <PanelCollapse>
                        <div className="os-filtros">
                            <div className="os-filtro">
                                <span className="os-filtro-titulo">Situação</span>
                                <select 
                                    className="os-filtro-input"
                                    value={situacao}  
                                    onChange={(el) => setSituacao(el.target.value)}
                                >
                                    <option value="NF">Não faturadas</option>
                                    <option value="PS">Filtrar</option>
                                </select>
                            </div>   

                            <ButtonPesquisa onClick={handleFiltra} loading={loading}/>

                            <div className="os-filtro" style={{marginLeft: 20}}>
                                <span className="os-filtro-titulo">Relatório</span>
                                <select 
                                    className="os-filtro-input"
                                    value={relatorio}  
                                    onChange={(el) => setRelatorio(el.target.value)}
                                >
                                    <option value="S">Sintético</option>
                                    <option value="A">Analítico</option>
                                </select>
                            </div>       

                            <ButtonImprime onClick={() => handleImprime()} />                        
                        </div>

                        {situacao === "PS" 
                        &&
                        <div className="os-filtros">
                            <div className="os-filtro">
                                <span className="os-filtro-titulo">Finalizadas entre</span>

                                <div className="os-filtro-data">
                                    <input
                                        className="os-filtro-input"
                                        style={{maxWidth: 120}}
                                        type="date"
                                        value={finalizadaInicial}
                                        onChange={el => setFinalizadaInicial(el.target.value)}
                                    />

                                    <span className="os-filtro-titulo">a</span>

                                    <input
                                        className="os-filtro-input"
                                        style={{maxWidth: 120}}
                                        type="date"
                                        value={finalizadaFinal}
                                        onChange={el => setFinalizadaFinal(el.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="os-filtro">
                                <span className="os-filtro-titulo">Número OS</span>
                                <input 
                                    style={{maxWidth: 120}}
                                    className="os-filtro-input"
                                    type="number"
                                    value={numeroOS}
                                    onChange={el => setNumeroOS(el.target.value)}
                                />
                            </div>

                            <div className="os-filtro">
                                <span className="os-filtro-titulo">Descrição</span>
                                <input 
                                    style={{minWidth: 200}}
                                    className="os-filtro-input"
                                    value={descricao}
                                    onChange={el => setDescricao(el.target.value)}
                                />
                            </div>                             

                            <div className="os-filtro">
                                <span className="os-filtro-titulo">Status faturamento</span>

                                <select 
                                    className="os-filtro-input"
                                    value={status}  
                                    onChange={(el) => setStatus(el.target.value)}
                                >
                                    <option value="TODOS">Todos</option>
                                    <option value="FATURADA">Somente faturadas</option>
                                    <option value="NAO_FATURADA">Somente não faturadas</option>
                                </select>                                
                            </div>

                            <div className="os-filtro">
                                <span className="os-filtro-titulo">Nota Fiscal</span>
                                <input 
                                    style={{maxWidth: 120}}
                                    className="os-filtro-input"
                                    type="number"
                                    value={numeroNF}
                                    onChange={el => setNumeroNF(el.target.value)}
                                />
                            </div>  

                            <div className="os-filtro">
                                <span className="os-filtro-titulo">Classificação</span>
                                <select 
                                    className="os-filtro-input"
                                    value={tipoServico}  
                                    onChange={(el) => setTipoServico(el.target.value)}
                                >
                                    <option value="">Todos</option>

                                    {tipoServicoList?.map((item, key) => {
                                        return (
                                            <option key={key} value={item.CODTIPO_SERVICO}>{item.DESCRICAO}</option>
                                        )
                                    })}
                                </select>
                            </div>  

                            <div className="os-filtro">
                                <span className="os-filtro-titulo">Solicitante</span>
                                <select 
                                    className="os-filtro-input"
                                    value={solicitante}  
                                    onChange={(el) => setSolicitante(el.target.value)}
                                >
                                    <option value="">Todos</option>

                                    {solicitanteList?.map((item, key) => {
                                        return (
                                            <option key={key} value={item.SOLICITANTE}>{item.SOLICITANTE}</option>
                                        )
                                    })}
                                </select>
                            </div>                                                                                                        
                        </div>                        
                        }
                    </PanelCollapse>
                   
                    <TotalRegistro data={data} />

                    <div className="os-marca-todos">
                        <input type="checkbox" id="id_marca_todos" onClick={(el) => toogleMarcaTodos(el)} />
                        <span>Marcar todos</span>
                    </div>
                    

                    <div className="os-main">
                        {data?.map(item => OrdemServicoItem(item, () => setModalDetails(!modalDetails), (value) => setItemDetails(value), relatorio))}

                        {!loading && Object.keys(data).length == 0 && <span className="os-sem-registro">Nenhum registro encontrado</span>}
                    </div>
                </div>
            </MenuSideBar>

            <TelaGeral
                isOpen={modalDetails}
                onRequestClose={() => setModalDetails(false)}
                titulo="Detalhes"
                buttonAux1={(<IoMdPrint size={28} title="Imprimir" className="tela-geral-topo-botao" onClick={() => handleImprime(itemDetails?.id)}/>)}
            >
                <div className="os-modal-content">
                    <div className="os-modal-details">
                        <div className="os-modal-detail">
                            <span className="os-modal-detail-titulo">Número</span>
                            <span className="os-modal-detail-valor">{itemDetails?.id}</span>
                        </div>
                    </div>

                    <div className="os-modal-details">
                        <div className="os-modal-detail">
                            <span className="os-modal-detail-titulo">Data solicitação</span>
                            <span className="os-modal-detail-valor">{itemDetails?.dataSolicitacao}</span>
                        </div>   
                        <div className="os-modal-detail">
                            <span className="os-modal-detail-titulo">Data execução</span>
                            <span className="os-modal-detail-valor">{itemDetails?.dataExecucao}</span>
                        </div>                                               
                    </div>

                    <div className="os-modal-details">
                        <div className="os-modal-detail">
                            <span className="os-modal-detail-titulo">Classificação</span>
                            <span className="os-modal-detail-valor">{itemDetails?.tipoServico}</span>
                        </div>   
                        <div className="os-modal-detail">
                            <span className="os-modal-detail-titulo">Contrato</span>
                            <span className="os-modal-detail-valor">{itemDetails?.contrato}</span>
                        </div>                                               
                    </div>  

                    <div className="os-modal-details">
                        <div className="os-modal-detail">
                            <span className="os-modal-detail-titulo">Solicitante</span>
                            <span className="os-modal-detail-valor">{itemDetails?.solicitante}</span>
                        </div>   
                        <div className="os-modal-detail">
                            <span className="os-modal-detail-titulo">Responsável</span>
                            <span className="os-modal-detail-valor">{itemDetails?.responsavel}</span>
                        </div>                                               
                    </div>  

                    <div className="os-modal-details">
                        <div className="os-modal-detail">
                            <span className="os-modal-detail-titulo">Tempo</span>
                            <span className="os-modal-detail-valor">{itemDetails?.tempoTotal} hora(s)</span>
                        </div>   
                        <div className="os-modal-detail">
                            <span className="os-modal-detail-titulo">Nota Fiscal</span>
                            <span className="os-modal-detail-valor">{itemDetails?.numeroNF ? itemDetails?.numeroNF : "Não emitida"}</span>
                        </div>                                               
                    </div>  

                    <div className="os-modal-details">
                        <div className="os-modal-detail">
                            <span className="os-modal-detail-titulo">Descrição</span>
                            <span className="os-modal-detail-valor">{itemDetails?.descricao}</span>
                        </div>         
                    </div>                 

                    <div className="os-modal-details">
                        <PanelCollapse titulo="Solicitação">
                            <div className="os-modal-detail">
                                {itemDetails?.solicitacao?.replace(/\\n/g, '\n').split('\n').map((item, key) => {
                                    return <span key={key} className="os-modal-detail-valor">{item}<br/></span>
                                })}
                            </div>
                        </PanelCollapse>
                    </div>

                    <div className="os-modal-details">
                        <PanelCollapse titulo="Problema encontrado/Alterações necessárias" open={false}>
                            <div className="os-modal-detail">
                                {itemDetails?.problema?.replace(/\\n/g, '\n').split('\n').map((item, key) => {
                                    return <span key={key} className="os-modal-detail-valor">{item}<br/></span>
                                })}
                            </div>
                        </PanelCollapse>
                    </div>    

                    <div className="os-modal-details">
                        <PanelCollapse titulo="Solução" open={false}>
                            <div className="os-modal-detail">
                                {itemDetails?.solucao?.replace(/\\n/g, '\n').split('\n').map((item, key) => {
                                    return <span key={key} className="os-modal-detail-valor">{item}<br/></span>
                                })}
                            </div>
                        </PanelCollapse>
                    </div> 

                    <div className="os-modal-details">
                        <PanelCollapse titulo="Observação" open={false}>
                            <div className="os-modal-detail">
                                {itemDetails?.observacao?.replace(/\\n/g, '\n').split('\n').map((item, key) => {
                                    return <span key={key} className="os-modal-detail-valor">{item}<br/></span>
                                })}
                            </div>
                        </PanelCollapse>
                    </div>                                                          
                </div>
            </TelaGeral>
        </>
    );
};

export default OrdemServico;