import React from "react";
import "./style.css";
import Modal from "react-modal";
import { Oval } from  "react-loader-spinner";

export default function Loading({isOpen}) {
    return (
        <Modal
            style={{overlay: {zIndex: 1000000}}}
            className="loading-container"
            isOpen={isOpen}
            appElement={document.getElementById("root")}
        >
            <Oval
                height={50}
                width={50}
                color="#b72126"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#b72126"
                strokeWidth={2}
                strokeWidthSecondary={4}
            />  
            <span className="loading-titulo">Aguarde...</span>
        </Modal>
    );
};