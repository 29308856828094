import { useContext } from "react";
import "./style.css";

import { AuthContext } from "../../context/Auth";
import {RxHamburgerMenu} from "react-icons/rx";

export default function Header({ menuToggled, menuBroken }) {
    const { title } = useContext(AuthContext);
    const userName = localStorage.getItem("userName");

    return (
        <div className="header-container">
            {menuBroken && (<RxHamburgerMenu size={24} color="black" onClick={() => menuToggled()}/>)}
            
            <span className="header-title">{title}</span>
        </div>
    );
};

export function copyToClipboard(text) {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
}