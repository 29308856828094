import React from "react";
import "./style.css";
import Header from "../../components/Header";
import MenuSideBar from "../../components/MenuSideBar";

function Home() {
    return (
        <MenuSideBar>
            {/* <h1>kaosdiaijdia</h1> */}
        </MenuSideBar>
    );
};

export default Home;
