import axios from "axios";
import { toastError } from "./library";

export const baseURL = (!process.env.NODE_ENV || process.env.NODE_ENV === "development") ? "http://172.17.1.84:8008" : "https://clientes-endpoint.defferrari.com.br";
//export const baseURL = "http://172.17.1.84:8008";
//export const baseURL = "http://clientes.defferrari.com.br:8008";

export default axios.create({
    baseURL: baseURL,
    timeout: 13000,
});

export function MENSAGEM_ERRO_HTTP() {
    toastError("Não foi possível conectar com o servidor.", 2500);
}
