import React, {createContext, useState, useEffect} from "react";
import api, { MENSAGEM_ERRO_HTTP } from "../../api";
import { useHistory} from "react-router-dom";
import Loading from "../../components/Loading";

const AuthContext = createContext({});

function AuthProvider({children}) {
    const history = useHistory();

    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState("");

    useEffect(() => {
        api.defaults.headers.common["content-type"]= "application/x-www-form-urlencoded";

        (async () => {
            try {
                const token = localStorage.getItem("token");
                if (token) {
                    let { data } = await handleLogin("", "", token);

                    if (data && data.code === 401) {
                        localStorage.removeItem("token");
                    }
                }               
            }
            catch {}
            finally {
                setLoading(false);
            }
        })();
      }, []);

    async function handleLogin(user, password, token) {
        let response;

        try {
            response = await (await api.post(
                "/login",
                {
                    user: user ? user : "",
                    password: password ? password : "",
                    token: token ? token : "",
                    dash: false
                }
            )).data;

            if (! response.error) {
                if (user)
                    localStorage.setItem("user", user);

                if (response?.userName)
                    localStorage.setItem("userName", response?.userName);

                if (response?.userAbrev)
                    localStorage.setItem("userAbrev", response?.userAbrev);

                localStorage.setItem("token", response.token);
                api.defaults.headers.Authorization = `Bearer ${response.token}`;
                setAuthenticated(true);
               
                if (user && password)
                    history.push("/bloqueto");                  
            }   
        } catch {
            if (user && password) 
                MENSAGEM_ERRO_HTTP();
        } finally {
            return response;
        }
    };

    function handleLogout() {
        setAuthenticated(false);
        localStorage.removeItem("token");
        api.defaults.headers.Authorization = undefined;
        history.push("/login");
    };

    if (loading)
        return <Loading isOpen={loading}/>

    return (
        <AuthContext.Provider
            value={{
                history,
                authenticated,
                setAuthenticated,
                handleLogin,
                handleLogout,
                title,
                setTitle
            }}
        >
            {children}
        </AuthContext.Provider>
    )
};

export {AuthContext, AuthProvider};