import React from "react";
import "./style.css";

import { useHistory } from "react-router-dom";

const Page404 = () => {
    const history = useHistory();

    return (
        <div className="page-404-container">
            <img alt="404" src={require("../../assets/img/404.png")} width={175} />

            <pre className="page-404-titulo">Página não encontrada  :(</pre>

            <button className="page-404-home" onClick={() => history.push("/bloqueto")}>Home</button>
        </div>
    )
}

export default Page404;