import "./style.css";

const TotalRegistro = ({ data }) => {
    return (
        <div className="total-registro-content">
            {data && data.length ? <span>Total de registros: {data.length}</span> : (<></>)}
        </div>
    );
};

export default TotalRegistro;