import React, {useState, useEffect, useContext, useRef} from "react";
import "./style.css";
import Package from "../../../package.json";

import { AuthContext } from "../../context/Auth";
import { Oval } from  "react-loader-spinner";
import { FaUserLarge, FaLock } from "react-icons/fa6";
import { ButtonOK } from "../../components/Button";

function Login() {
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const userRef = useRef(null);
    const passwordRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { handleLogin } = useContext(AuthContext);

    useEffect(() => {
        let _user = localStorage.getItem("user");
        if (_user) {
            setUser(_user);
            passwordRef.current.focus();
        } else 
            userRef.current.focus();
    }, []);

    const handlevalidation = async () => {
        setError("");

        if (! user) {
            setError("Informe o código");
            return;
        } else if (! password) {
            setError("Informe a senha");
            return;
        }

        setLoading(true);

        let response = await handleLogin(user, password, "");

        setLoading(false);            

        if (response && response.message)
            setError(response.message);
    };

    function handleEnter(e) {
        if (e.key.toLowerCase() === "enter") 
            if (e.target.id === "input_usuario")
                passwordRef.current.focus();
            else if (e.target.id === "input_senha")
                handlevalidation();
    }

    return (
        <div className="container-login">
            <div className="login">
                <img alt="logo" src={require("../../assets/img/logo_defferrari.png")} className="logo" />

                <span className="span-alert">{error}</span>

                <div className="container-input">
                    <FaUserLarge size={14} color="gray" />
                    <input 
                        id="input_usuario"
                        ref={userRef}
                        type="number"
                        className="input"
                        placeholder="Código"
                        value={user}
                        onChange={e => setUser(e.target.value)}
                        onKeyDown={handleEnter}
                    />
                </div>

                <div className="container-input">
                    <FaLock size={14} color="gray" />
                    <input 
                        id="input_senha"
                        ref={passwordRef}
                        type="password"
                        className="input"
                        placeholder="Senha"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        onKeyDown={handleEnter}
                    />
                </div>    

                {loading ?
                    <Oval
                        height={32}
                        width={32}
                        color="#b72126"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#b72126"
                        strokeWidth={2}
                        strokeWidthSecondary={4}

                    />
                : <ButtonOK title="Entrar" onClick={handlevalidation}/>            
                }

                <span className="version">Versão {Package.version}</span>
            </div>
        </div>
    );
};

export default Login;